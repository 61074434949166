import { z } from 'zod';

const detailSchema = z.union([
  z
    .array(z.string())
    .nonempty()
    .transform((value) => value.join('\n')),
  z
    .record(
      z.string(),
      z.union([
        z.string(),
        z
          .array(z.union([z.string(), z.unknown().transform((value) => JSON.stringify(value))]))
          .nonempty()
          .transform((value) => value.join('\n')),
      ]),
    )
    .refine((value) => Object.keys(value).length > 0, 'Keys count must be more than 0')
    .transform((value) => {
      return Object.entries(value)
        .map(([fieldName, fieldErrorMessage]) => `${fieldName}: ${fieldErrorMessage}`)
        .join('\n');
    }),
  z.unknown().transform(() => null),
]);

const singleErrorSchema = z.union([
  z.string(),
  z
    .object({
      detail: detailSchema.nullish(),
      message: z.string(),
    })
    .transform((value) => {
      if (typeof value.detail === 'string') {
        return value.detail;
      }
      return value.message;
    }),
]);

const errorsSchema = z.union([
  singleErrorSchema,
  z
    .array(singleErrorSchema)
    .nonempty()
    .transform((value) => value.join('\n')),
]);

const errorResponseJsonSchema = z.union([
  z.string(),
  z
    .object({
      errors: errorsSchema,
    })
    .transform((value) => value.errors),
  z
    .object({
      message: z.string(),
    })
    .transform((value) => value.message),
  z
    .object({
      error: z.string(),
    })
    .transform((value) => value.error),
  z
    .object({
      errorMessage: z.string(),
    })
    .transform((value) => value.errorMessage),
  z.unknown().transform(() => 'Unknown error'),
]);

export default function extractErrorMessageFromApiError(errorData: unknown): string {
  const parsingResult = errorResponseJsonSchema.safeParse(errorData);

  if (parsingResult.success) {
    return parsingResult.data;
  }

  return 'Unknown error';
}

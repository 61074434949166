enum ApiResultStatus {
  OK = 'ok',
  ERROR = 'error',
  CANCELLED = 'cancelled',
  NOT_FOUND = 'not-found',
  ACCESS_DENIED = 'access-denied',
  LICENSE_EXPIRED = 'license-expired',
}

export default ApiResultStatus;

import { onBeforeUnmount } from 'vue';

import MayBePromise from '../../../lib/types/MayBePromise';

import getApiClient from '../../../shared/api/api-client/getApiClient';
import extractErrorMessageFromLicenseExpiringErrorResponseOriginalData from './extractErrorMessageFromLicenseExpiringErrorResponseOriginalData';
import extractTrackerIdFromLicenseExpiringErrorResponseOriginalData from './extractTrackerIdFromLicenseExpiringErrorResponseOriginalData';

const LICENSE_EXPIRED_HTTP_CODE = 402;

export default function onGotLicenseExpiredApiResponse(
  callback: (payload: { errorMessage: string }) => MayBePromise<void>,
) {
  const client = getApiClient();

  const stopInterceptor = client.useResponseInterceptor(async (response) => {
    const isLicenseExpired = !response.ok && response.status === LICENSE_EXPIRED_HTTP_CODE;

    if (!isLicenseExpired) {
      return response;
    }

    const json = await response.json();

    const errorMessage = extractErrorMessageFromLicenseExpiringErrorResponseOriginalData(json) ?? '';
    const trackerId = extractTrackerIdFromLicenseExpiringErrorResponseOriginalData(json);

    void callback({
      errorMessage: `${errorMessage}\n\nTracker id: ${trackerId}`,
    });

    return response;
  });

  onBeforeUnmount(stopInterceptor);
}

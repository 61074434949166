enum SettingsRouteName {
  SETTINGS = 'settings',
  TRACKING_LINKS_SETTINGS = 'tracking-links-settings',
  USER_SETTINGS = 'user-settings',
  STATS_SETTINGS = 'stats-settings',
  COLUMNS_SETTINGS = 'columns-settings',
  PUBLIC_API_SETTINGS = 'public-api-settings',
  CLICK_API_SETTINGS = 'click-api-settings',
  LANDING_PAGE_PROTECT_SETTINGS = 'landing-page-protect-settings',
  INTEGRATION = 'integration',
  POSTBACK_PROCESSING_SETTINGS = 'postback-processing-settings',
  IMPORT_EXPORT_SETTINGS = 'import-export-settings',
  URL_CUSTOMIZATION_SETTINGS = 'url-customization-settings',
}

export default SettingsRouteName;
